import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useState } from 'react';
import { motion, AnimatePresence } from "framer-motion"


import { useCallback, useRef } from 'react';




export default function Login( {setLoggedIn, setData, apiEndpoint} ) {

    const [disabled, setDisabled] = useState(true);

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    function handleUsernameChange(event) {
        setUsername(event.target.value);
        updateDisabled();
    }

    function handlePasswordChange(event) {
        setPassword(event.target.value);
        updateDisabled();
    }

    function updateDisabled() {
        if(username.length > 0 && password.length > 0) {
            setDisabled(false);
        }
    }

    function handleSubmit(event) {
        event.preventDefault();
        login();   
    }
    async function login() {
        let response = await fetch(apiEndpoint + '/login?username=' + username + '&password=' + password);
        if(response.status !== 200) {
            alert('Invalid username or password');
            return;
        }
        let data = await response.json();
        let token = data.token;
        localStorage.setItem('token', token);
        
        // fetch user data
        response = await fetch(apiEndpoint + '/user?token=' + data.token);
        data = await response.json();
        data.token = token;
        console.log("setting data");
        console.log(data);

        setData(data);
        setLoggedIn(true);
    }


    return(
        <div
            className="login-container"
            key="login"
            initial={{ marginLeft: "1em" }}
            animate={{ marginLeft: "0em" }}
            exit={{ marginLeft: "-1em" }}
            transition={{ duration: 0.5 }}
            >
            <motion.div
            className="inner-container"
            initial={{ marginTop: "3em", opacity: 0 }}
            animate={{ marginTop: "0em", opacity: 1 }}
            exit={{ marginTop: "-3em", opacity: 0 }}
            key="login">
            <TextField label="Username" variant="filled" onChange={handleUsernameChange} value={username}/>
            <TextField label="Password" variant="filled" type="password" onChange={handlePasswordChange} value={password}/>
            <Button className="login-button" variant="contained" onClick={handleSubmit} disabled={disabled}>Login</Button>
            </motion.div>
        </div>
    );
}