import { motion, AnimatePresence } from "framer-motion"
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';



export default function HomePage( {data, logout, apiEndpoint}) {
  function handleAnnouncmentChange(event) {
    console.log(apiEndpoint);
    fetch(apiEndpoint + '/set_announcement?token=' + data.token + '&announcement=' + event.target.value);
    console.log("test");
  }
    return (
        <motion.div className=""
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        key="logged-in"
        >
          <div className="main-container">
            <h4 className="name">Welcome, {data.name}!</h4>
            <h4>Change Current Announcment:</h4>
              <TextField className="announcement-textfield" sx={{height: "1em"}} defaultValue={data.announcement} onChange={handleAnnouncmentChange} />
          </div>

          <div className="logout-container">
          <Button variant="contained" onClick={logout} sx={{ textTransform: 'none' }}>Logout</Button>
          </div>
        </motion.div>
    );
}