import { useEffect, useState} from "react";
import { motion, AnimatePresence } from "framer-motion"
import HomePage from "./HomePage";

import Login from "./Login";
import "./style.css";



function App() {
  const apiEndpoint = 'https://api.hcsa.tech';
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);


  function logout() {
    localStorage.removeItem('token');
    setLoggedIn(false);
  }
  
  async function script() {
    const token = localStorage.getItem('token');
    if (token) {
      let response = await fetch(apiEndpoint + '/user?token=' + token);
      if(response.status !== 200) {
        localStorage.removeItem('token');
        setLoading(false);
        return;
      }
      let data = await response.json();
      data.token = token;
      console.log("setting data"  + data);
      setData(data);
      console.log(data);
      console.log('Logged in');
      setLoggedIn(true);
      setLoading(false);
    } else {
      setLoading(false);
    }
  }
  useEffect(() => {
    script().then(() => {console.log("Ran");})
}
, []);


  return (
    <div className="App">
      <AnimatePresence>
        {loading ? (
          <motion.div
            className="loading-container"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          key="loading"
          >
            <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
          </motion.div>
        ) : (loggedIn ? (
          <HomePage data={data} logout={logout} apiEndpoint={apiEndpoint} />
        ) : (
            <Login setLoggedIn={setLoggedIn} setData={setData} apiEndpoint={apiEndpoint} />
        ))}
      </AnimatePresence>
    </div>
  );
            


  if (loading) {
    return (
      <div className="App">
        <h1>Loading...</h1>
      </div>
    );
  }
  if (loggedIn) {
    return (
      <AnimatePresence>
      <motion.div
        key="loggedIn"
        className="App"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        >
        <h1>Welcome, {data.name}!</h1>
        <button onClick={logout}>Logout {data.username}</button>
      </motion.div>
      </AnimatePresence>
    );
  }
  else
    return (
      <div className="App">
        <Login setLoggedIn={setLoggedIn} setData={setData} apiEndpoint={apiEndpoint} />
      </div>
    );
}

export default App;
